import {
  CredentialsChecked,
  FrontendCredentialsChecked,
} from '@app/models/objects/credentials-checked';

export interface State {
  isLoading?: boolean;
  error?: any;
  credentials: CredentialsChecked;
  frontendCredentials: FrontendCredentialsChecked;
}

export const initialState: State = {
  isLoading: false,
  error: null,
  credentials: null,
  frontendCredentials: {
    email: {
      status: null,
      label: '',
    },
    username: {
      status: null,
      label: '',
    },
  },
};
