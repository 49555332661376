import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { State } from './state';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const getCredentialsChecked: MemoizedSelector<
  object,
  State
> = createFeatureSelector<State>('credentialsCheck');

export const selectCredentialsChecked = createSelector(
  getCredentialsChecked,
  (state: State) => {
    return state.credentials;
  },
);

export const selectFrontendCredentialsChecked = createSelector(
  getCredentialsChecked,
  (state: State) => {
    return state.frontendCredentials;
  },
);
