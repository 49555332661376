import {
  CredentialsChecked,
  FrontendCredentialsChecked,
} from '@app/models/objects/credentials-checked';
import { Action } from '@ngrx/store';

export enum ActionTypes {
  CREDENTIALS_CHECK_FRONTEND_REQUEST = '[Credential Check] Credential Check Frontend Request',
  CREDENTIALS_CHECK_FRONTEND_FAILURE = '[Credential Check] Credential Check Frontend Failure',
  CREDENTIALS_CHECK_FRONTEND_SUCCESS = '[Credential Check] Credential Check Frontend Success',

  CREDENTIALS_CHECK_REQUEST = '[Credential Check] Credential Check Request',
  CREDENTIALS_CHECK_FAILURE = '[Credential Check] Credential Check Failure',
  CREDENTIALS_CHECK_SUCCESS = '[Credential Check] Credential Check Success',

  RESET_STATE = '[Credential Check] Reset State',
}

export class CredentialCheckFrontendRequestAction implements Action {
  readonly type = ActionTypes.CREDENTIALS_CHECK_FRONTEND_REQUEST;
  constructor(public payload: { data: any }) {}
}

export class CredentialCheckFrontendSuccessAction implements Action {
  readonly type = ActionTypes.CREDENTIALS_CHECK_FRONTEND_SUCCESS;
  constructor(public payload: { data: FrontendCredentialsChecked }) {}
}

export class CredentialCheckFrontendFailureAction implements Action {
  readonly type = ActionTypes.CREDENTIALS_CHECK_FRONTEND_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class CredentialCheckRequestAction implements Action {
  readonly type = ActionTypes.CREDENTIALS_CHECK_REQUEST;
  constructor(
    public payload: {
      data: {
        email?: string;
        username?: string;
      };
    },
  ) {}
}

export class CredentialCheckSuccessAction implements Action {
  readonly type = ActionTypes.CREDENTIALS_CHECK_SUCCESS;
  constructor(public payload: { data: CredentialsChecked }) {}
}

export class CredentialCheckFailureAction implements Action {
  readonly type = ActionTypes.CREDENTIALS_CHECK_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class CredentialCheckResetSuccessAction implements Action {
  readonly type = ActionTypes.RESET_STATE;
}

export type Actions =
  | CredentialCheckFrontendRequestAction
  | CredentialCheckFrontendSuccessAction
  | CredentialCheckFrontendFailureAction
  | CredentialCheckRequestAction
  | CredentialCheckSuccessAction
  | CredentialCheckFailureAction
  | CredentialCheckResetSuccessAction;
