import { CredentialsChecked } from '@app/models';

import { Actions, ActionTypes } from './actions';
import { initialState, State } from './state';

export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.RESET_STATE: {
      return initialState;
    }

    case ActionTypes.CREDENTIALS_CHECK_FRONTEND_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.CREDENTIALS_CHECK_FRONTEND_SUCCESS: {
      const newCredentials = state.frontendCredentials;
      Object.keys(action.payload.data).forEach((credentialName) => {
        const { label, status } = action.payload.data[credentialName];
        newCredentials[credentialName] = {
          label,
          status,
        };
      });
      return {
        ...state,
        isLoading: false,
        error: null,
        frontendCredentials: { ...newCredentials },
      };
    }

    case ActionTypes.CREDENTIALS_CHECK_FRONTEND_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.CREDENTIALS_CHECK_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.CREDENTIALS_CHECK_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        credentials: action.payload.data,
      };
    }

    case ActionTypes.CREDENTIALS_CHECK_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
}
