import { Injectable } from '@angular/core';
import { ICoreState } from '@app/core/+state/core.reducer';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { CredentialsCheckFrontendResponse } from '@app/models/responses/credentials-check-frontend-response';
import { CredentialsCheckFrontendService } from '@app/services/credentials-check.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { CheckCredentials } from '../../models';

import * as featureActions from './actions';

@Injectable()
export class CredentialsCheckStoreEffects {
  constructor(
    private credentialsCheckFrontendService: CredentialsCheckFrontendService,
    private actions$: Actions,
    private store: Store<{ core: ICoreState }>,
    private errorHandler: ErrorHandlerService,
  ) {}

  credentialCheckFrontendEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.CredentialCheckFrontendRequestAction>(
        featureActions.ActionTypes.CREDENTIALS_CHECK_FRONTEND_REQUEST,
      ),
      withLatestFrom(this.store),
      switchMap(
        ([action, store]: [
          featureActions.CredentialCheckFrontendRequestAction,
          { core: ICoreState },
        ]) => {
          return this.credentialsCheckFrontendService
            .checkFrontendCredentials(action.payload.data)
            .pipe(
              map(
                ({ data }: CredentialsCheckFrontendResponse) =>
                  new featureActions.CredentialCheckFrontendSuccessAction({
                    data: data[0],
                  }),
              ),
              catchError((error) => {
                this.errorHandler.handle(error);
                return of(
                  new featureActions.CredentialCheckFrontendFailureAction({
                    error,
                  }),
                );
              }),
            );
        },
      ),
    ),
  );

  credentialCheckEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.CredentialCheckRequestAction>(
        featureActions.ActionTypes.CREDENTIALS_CHECK_REQUEST,
      ),
      withLatestFrom(this.store),
      switchMap(
        ([action, store]: [
          featureActions.CredentialCheckRequestAction,
          { core: ICoreState },
        ]) => {
          return this.credentialsCheckFrontendService
            .checkCredentials(action.payload.data)
            .pipe(
              map(
                ({ data }: IResponseSuccess<CheckCredentials>) =>
                  new featureActions.CredentialCheckSuccessAction({
                    data: data[0],
                  }),
              ),
              catchError((error) => {
                this.errorHandler.handle(error);
                return of(
                  new featureActions.CredentialCheckFrontendFailureAction({
                    error,
                  }),
                );
              }),
            );
        },
      ),
    ),
  );
}
